import { isRunTime } from "./isRunTime";

const getQuery = (field = false) => {
  if (!isRunTime()) return {};

  const urlParams = new URLSearchParams(window.location.search);
  const ret = {};

  for (const [key, value] of urlParams.entries()) {
    try {
      ret[key] = JSON.parse(value); // Parse JSON if possible
    } catch {
      ret[key] = value; // Keep as string if parsing fails
    }
  }

  if (field) {
    if (ret[field]) {
      return ret[field];
    }

    return null;
  }

  return ret;
};

const setQuery = ({ key, value, pushState = false }) => {
  if (isRunTime() && key && value !== undefined) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value);
    if (pushState) {
      window.history.pushState({}, "", `?${urlParams.toString()}`);
    } else {
      window.history.replaceState({}, "", `?${urlParams.toString()}`);
    }
  }
};

// same as resetQuery, but staying in the same page

const removeQueryParams = () => {
  if (isRunTime()) {
    const url = new URL(window.location);
    url.search = ""; // Clear the query strings
    window.history.replaceState({}, "", url.toString());
  }
};

const resetQuery = () => {
  if (isRunTime()) {
    window.history.replaceState({}, "", "/")
  }
}

export { getQuery, setQuery, resetQuery, removeQueryParams };
