import request from 'src/utils/request';
import { CONNECT, INNTOPIA_SALES_ID, SIGNATURE_RESOURCE } from 'src/utils/environment';
import { getCurrentCurrency, getSession } from 'src/utils/localStorage';

export function getSalesId(salesId = INNTOPIA_SALES_ID) {
  const currency = getCurrentCurrency();

  if (typeof currency === 'object' && currency && currency.change_store && currency.store) {
    if (String(currency.store) !== String(salesId)) {
      salesId = currency.store;
    }
  }
  return salesId;
};

export function getCountries () {
  //in country searcher and country picker we are already using iso library (either remove library or this req)
  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/inntopia/getCountries`,
      method: 'GET',
      params: {}
    })
    .then((response) => {
      const data = response.data || null;

      if (data.length > 0) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getCountries', err);
      reject(err)
    });
  })
};

export function getCountryStates ({ state }) {
  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/inntopia/getStates`,
      method: 'GET',
      params: { state }
    })
    .then((response) => {
      const data = response.data || null;

      resolve(data);
    })
    .catch(err => {
      console.log('error service/getCountryStates', err);
      reject(err)
    });
  })
};

export function getCurrencyExchange ({ currency }) {
  return new Promise((resolve, reject) => {
    request({
      url: `https://7loc0h7tkk.execute-api.us-east-1.amazonaws.com/default/CurrencyExchange`,
      method: 'GET',
      params: { base: currency }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.base && data.base === currency) {
        resolve(data);
      }

      reject(null)
    })
    .catch(err => {
      console.log('error service/getCurrencyExchange', err);
      reject(err)
    });
  })
};

export function getIframeUrl () {
  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/flamingo/security?resource=d17ot6k2h3k3xk&v=${new Date().getTime()}&tokenization=false&sessionId=${getSession()?.uuid}&salesId=${INNTOPIA_SALES_ID}&channelSalesId=$${INNTOPIA_SALES_ID}&environment=production`,
      method: 'GET',
    })
    .catch(err => reject(err))
    .then(res => {
        console.log("response url", res.data.signed_url)
        if (res.data && res.data.signed_url) {
          resolve(res.data.signed_url);
        }
    })
  })
};