import { getLanguageField } from "./language";

/**
 * Get correct slug
 * @param language current language
 * @param default_language destination default language
 * @param translations translations array
 * @param translation_key key to slug
 * @returns page path
 */
export function getSlug(
    language: string,
    default_language: string,
    translations: TTranslations<{slug: string}>,
    translation_key: string = "slug",
): string {
    const isDefaultLanguage = language === default_language;
    let prefix = isDefaultLanguage ? '/' : `/${language}/`;
    let slug = getLanguageField(translations, language, translation_key);
    let slugp = getLanguageField(translations, language, 'slug_prefix');
    const link = prefix + (slugp ? slugp + "/" : "") + slug;
    if (link[0] === "/" && link[1] === "/") return link.replace(/\/+/g, "/");
    return link;
}

export function getEntrySlug(entry:TEntry, selectedLanguage: string, defaultLanguage: string):string{
    const { view_mode } = entry;
    let slug_prefix = "";
    let slug = "";
    const language = selectedLanguage !== defaultLanguage ? selectedLanguage : "";
    if (view_mode === "external") return getLanguageField(entry.external_link_translations, selectedLanguage, "external_link") as string || "";
    if (view_mode === "page") {
      slug_prefix = getLanguageField(entry.translations, selectedLanguage, "slug_prefix") as string || "";
      slug = getLanguageField(entry.translations, selectedLanguage, "slug") as string || "";
    }
    if (view_mode === "none") return "";
    else if (view_mode === "internal") {
      slug = getLanguageField(entry?.internal_link?.translations, selectedLanguage, "slug") as string || "";
    }
    let url = `/${language}/${slug_prefix}/${slug}`.replace(/\/+/g, "/");;
    return url;
}


export function isSameLocation(url1: string, url2: string) {
    let _url1: string = url1.split('#')[0];
    let _url2: string = url2.split('#')[0];

    if (_url1.endsWith('/')) { _url1 = _url1.substring(0, _url1.length - 1); }
    if (_url2.endsWith('/')) { _url2 = _url2.substring(0, _url2.length - 1); }

    return _url1 === _url2;
}