import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getUserIpGeoInfo, setUserIpGeoInfo } from 'src/utils/localStorage';

export function getIp () {
  return new Promise((resolve, reject) => {
    const info = getUserIpGeoInfo();
    if (info && info.ip) {
      const now = new Date();
      const lastUpdate = new Date(info.date);

      const differenceInHours = (now - lastUpdate) / (1000 * 60 * 60);

      if (differenceInHours <= 8) {
        return resolve(info);
      }
    }

    request({
      url: `https://kymguavwd7.execute-api.us-east-1.amazonaws.com/ip/`,
      method: 'GET',
      params: {}
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ip) {
        setUserIpGeoInfo({ip: data.ip, date: new Date()});
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getIp', err);
      reject(err)
    });
  })
};

export function getGeoInfo ({ ip }) {
  return new Promise((resolve, reject) => {
    const info = getUserIpGeoInfo();
    if (info && info.country) {
      const now = new Date();
      const lastUpdate = new Date(info.date);

      const differenceInHours = (now - lastUpdate) / (1000 * 60 * 60);

      if (differenceInHours <= 8) {
        return resolve(info);
      }
    }

    request({
      url: `${CONNECT}/system/getGeoInfo`,
      method: 'GET',
      params: {
        ip
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ip) {
        setUserIpGeoInfo({ip: data.ip, country: data.country, date: new Date()});
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getGeoInfo', err);
      reject(err)
    });
  })
};