/**
 * Url location to request the spotlio connect
 */
export const CONNECT = process.env.GATSBY_CONNECT_URL || "default-development-value";

/**
 * It's the alias of the site in connect-side
 */
export const CONNECT_CLIENT = process.env.GATSBY_CONNECT_CLIENT || "default-development-value";

/**
 * It's the ID of the payment gateway defined on strapi
 */
export const CONNECT_GATEWAY = process.env.GATSBY_CONNECT_GATEWAY || "default-development-value";

/**
 * if defined, destination has inntopia integration
 * use to send salesid to requests or make some inntopia conditions
 */
export const INNTOPIA_SALES_ID = process.env.GATSBY_INNTOPIA_SALES || false;

/**
 * Determine running in run time
 * 
 * @returns {boolean} browser environment found
 */
export const RUNTIME = function runtime() {
  return typeof window !== "undefined" || typeof document !== "undefined";
}();

/**
 * Determine running in build time
 */
export const BUILDTIME = !RUNTIME;


export const SIGNATURE_RESOURCE = process.env.SIGNATURE_RESOURCE || "default-development-value";

