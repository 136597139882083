import React from "react";
import Link from "./../link";
import Icon from "./../icon";

type ButtonProps = {
    link?: string | undefined;
    text: string | undefined;
    before?: React.ReactNode | undefined;//Now it's beign used to render icon so we will treat this prop as an icon -> FLM-5559 (different alignment when it has icon)
    after?: React.ReactNode | undefined;
    icon?: React.ReactNode | undefined;//get icon -> can't render type soft + icon (cta button standards requires both)
    color?: string | undefined;
    bgcolor?: string | undefined;
    hovercolor?: string;
    hoverbgcolor?: string;
    hoverborder?: string;
    type?: "primary" | "secondary" | "soft" | "icon" | undefined;
    roundType?: "square" | "rounded" | "full-rounded" | undefined;
    radius?: string | undefined;
    open_in_same_tab?: boolean;
    open_in_same_tab_external?: boolean;
    padx?: boolean | string | undefined;
    border?: string | undefined;
    onClick?: (e: any) => void;
    className?: string;
    useDiv?: boolean;
    useButton?: boolean;
    disabled?: boolean;
    loading?: boolean;
}

export default class CTAButton extends React.Component<ButtonProps> {
    get typeclassName() {
        if (this.props.type === 'primary') return 'cta-button';
        if (this.props.type === 'secondary') return 'cta-secondary-button';
        if (this.props.type === 'soft') return 'cta-soft-button';
        if (this.props.type === 'icon') return 'cta-button';
        return 'cta-soft-button';
    }

    get icon() {
        if (this.props.icon) return this.props.icon;
        if (this.props.type === 'soft' || !this.props.type) return <Icon icon="ArrowRight" className="ml-1 w-4 h-4 min-w-[16px] min-h-[16px]" />
        return this.props.icon;
    }

    get className() {
        return `${this.props.className || ""} ${this.typeclassName}`;
    }

    get borderColor() {
        if (this.props.border) return this.props.border;
        return undefined;
    }

    get buttonTextClass() {
        return "buttonText";
    }

    get borderRadius() {
        if (this.props.radius) return this.props.radius;
        if (this.props.roundType === 'square') return '0px';
        if (this.props.roundType === 'rounded') return '8px';
        if (this.props.roundType === 'full-rounded') return '9999px';
        return undefined;
    }

    get style() {
        let paddingLeft = this.props.padx ? (typeof this.props.padx === 'string' ? this.props.padx : '18px') : undefined;
        let paddingRight = this.props.padx ? (typeof this.props.padx === 'string' ? this.props.padx : '18px') : undefined;
        if (this.props.type === 'icon') { paddingLeft = "0px"; paddingRight = "0px"; }

        return {
            '--button-background-color': this.props.bgcolor,
            '--button-text-color': this.props.color,
            '--button-border-color': this.borderColor,
            '--button-hover-background-color': this.props.hoverbgcolor,
            '--button-hover-text-color': this.props.hovercolor,
            '--button-border-hover-color': this.props.hoverborder,
            paddingLeft,
            paddingRight,
            borderRadius: this.borderRadius,
            borderWidth: typeof this.props.border === "string" ? 1 : undefined,
        }
    }

    render() {
        //FLM-4889 -> render button even if it has no text
        // if (this.props.text === "" || !this.props.text) return null;
        return <Link
            loading={this.props.loading}
            disabled={this.props.disabled}
            title={this.props.text}
            href={this.props.link}
            onClick={this.props.onClick}
            style={this.style}
            target={(this.props.open_in_same_tab === true || this.props.open_in_same_tab_external === true) ? '_self' : '_blank'}
            className={`${this.className} pointer-events-auto cursor-pointer ${this.buttonTextClass} flex justify-center items-center m-0 bg-[color:var(--button-background-color)] text-[color:var(--button-text-color)]`}
            useDiv={this.props.useDiv}
            useButton={this.props.useButton}
        >
            {this.props.before}
            {this.props.text}
            {this.icon}
            {this.props.after}
        </Link>;
    }
}