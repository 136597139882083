import React from "react";

import {getLabelForCTAButton, getLinkForCTAButton} from "./ctabutton.utils";
import CTAButtonWrapper from "src/components/ctabutton/ctabuttonwrapper";
import ImageV2 from "src/components/image/image.v2";
import { MaterialIcon } from "src/components/icon";


function colorWithOpacity(color: string | undefined): string {
    if (!color) return "transparent";
    return `${color}A9`;
}

type CTAButtonV2Props = {
    language: string;
    defaultLanguage: string;
    ctabutton: TCTAButton;
    className?: string;
    loading?: boolean;
    onClick?: () => void;
    noLink?: boolean;
    useDiv?: boolean;
    useButton?: boolean;
    disabled?: boolean;
}

export default class CTAButtonV2 extends React.Component<CTAButtonV2Props> {

    static getLabel(call_to_action: TCTAButton, language: string, defaultLanguage: string) {
        return getLabelForCTAButton(call_to_action, language, defaultLanguage);
    }

    static getLink(call_to_action: TCTAButton, language: string, defaultLanguage: string) {
        return getLinkForCTAButton(call_to_action, language, defaultLanguage);
    }

    render() {
        const call_to_action = this.props.ctabutton;

        const buttonText = CTAButtonV2.getLabel(call_to_action, this.props.language, this.props.defaultLanguage);
        const buttonLink = this.props.noLink ? undefined : CTAButtonV2.getLink(call_to_action, this.props.language, this.props.defaultLanguage);

        if (call_to_action.cta_button_type === 'soft') call_to_action.cta_button_icon_position = 'left';
        const iconMargin = call_to_action.cta_button_icon_position === 'right' ? "ml-2" : "mr-2";

        let icon = undefined;
        if (call_to_action.cta_button_icon_type === 'custom' && call_to_action.cta_button_icon?.imageFile)
            icon = <ImageV2 asSvgIcon={true} id={"cta_" + call_to_action.id} src={call_to_action.cta_button_icon?.imageFile} className={`w-6 h-6 ${iconMargin}`} />
        else if (call_to_action.cta_button_icon_type === 'material')
            icon = <MaterialIcon className={`w-6 h-6 ${iconMargin}`} icon={call_to_action.cta_button_material_icon} />;

        const useLayoutColors = call_to_action.use_default_layout_colors === true;

        return <CTAButtonWrapper
            disabled={this.props.disabled}
            className={this.props.className}
            useDiv={this.props.useDiv}
            loading={this.props.loading}
            useButton={this.props.useButton}
            type={call_to_action.cta_button_type}
            link={buttonLink}
            onClick={this.props.onClick}
            text={buttonText}
            icon={icon}
            iconPosition={call_to_action.cta_button_icon_position}
            open_in_same_tab={call_to_action.open_in_same_tab}
            open_in_same_tab_external={call_to_action.open_in_same_tab_external}
            primary_cta_button_label_color={useLayoutColors ? undefined : call_to_action.cta_button_label_color?.value}
            primary_cta_button_hover_label_color={useLayoutColors ? undefined : call_to_action.cta_button_hover_label_color?.value}
            primary_cta_button_color={useLayoutColors ? undefined : call_to_action.cta_button_background_color?.value}
            primary_cta_button_hover_color={useLayoutColors ? undefined : call_to_action.cta_button_hover_background_color?.value}
            secondary_cta_button_label_color={useLayoutColors ? undefined : call_to_action.cta_button_label_color?.value}
            secondary_cta_button_hover_label_color={useLayoutColors ? undefined : call_to_action.cta_button_hover_label_color?.value}
            secondary_cta_button_outline_color={useLayoutColors ? undefined : call_to_action.cta_button_outline_color?.value}
            secondary_cta_button_hover_outline_color={useLayoutColors ? undefined : call_to_action.cta_button_hover_outline_color?.value}
            secondary_cta_button_background_color={useLayoutColors ? undefined : colorWithOpacity(call_to_action.cta_button_background_color?.value)}
            secondary_cta_button_hover_background_color={useLayoutColors ? undefined : colorWithOpacity(call_to_action.cta_button_hover_background_color?.value)}
            soft_button_label_color={useLayoutColors ? undefined : call_to_action.cta_button_label_color?.value}
            soft_button_hover_label_color={useLayoutColors ? undefined : call_to_action.cta_button_hover_label_color?.value}
        />
    }
}